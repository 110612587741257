<template>
  <div>
    <h2 v-html="$t('search_contact_attributes_relations_card')"></h2>
    <!-- Label Bundle -->
    <!-- :item-title="$route.params.langKey" -->

    <contactParentIdPickerVue
      v-if="showPicker"
      :label="$t('contactparentorganisationid')"
      name="contactParentIdPickerVue"
      v-model="storeToUse.contactParentId"
      @keyup.enter="search"
    />

    <div v-for="attributeX in storeToUse.ContactAttribute" :key="storeName + attributeX.id">
      <v-text-field
        v-bind:key="'Pickerattribute' + attributeX.id"
        :id="'attribute' + attributeX.id"
        v-model="attributeX.value"
        :label="attributeX[$route.params.langKey]"
        type="text"
        @keyup.enter="search"
      />
    </div>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { defineComponent } from 'vue'
import contactParentIdPicker from '@/components/pickers/contactParentIdPicker.vue'

import { useSearchContactAttributesRelationsPickerStore } from '@/store/SearchContactAttributesRelationsPickerStore'
import { useSearchContactAttributesRelationsStore } from '@/store/SearchContactAttributesRelationsStore'

export default defineComponent({
  name: 'searchContactAttributeAndRelation',
  props: {
    useAsPicker: { type: Boolean, required: false, default: false },
    showPicker: { type: Boolean, required: true },
    initFromUrl: { type: Boolean, required: false, default: true },
    storeName: { type: String, required: true }
    //SearchContactAttributesRelationsPickerStore
    //SearchContactAttributesRelationsStore
  },
  extends: baseSearchVue,
  data() {
    return {
      test: 1545
    }
  },
  components: {
    // this is the workarround to handle the recursion, but it doesent work anymore?
    //contactParentIdPickerVue: () => import('@/components/pickers/contactParentIdPicker.vue')
    contactParentIdPickerVue: contactParentIdPicker
  },
  computed: {
    storeToUse() {
      switch (this.storeName) {
        case 'SearchContactAttributesRelationsPickerStore': {
          return useSearchContactAttributesRelationsPickerStore()
        }
        case 'SearchContactAttributesRelationsStore': {
          return useSearchContactAttributesRelationsStore()
        }
        default: {
          throw new Error('unknown Store:' + this.storeName)
        }
      }
    }
  },
  methods: {
    fetchAttributesNow() {
      switch (this.storeName) {
        case 'SearchContactAttributesRelationsPickerStore': {
          useSearchContactAttributesRelationsPickerStore().fetchContactAttributes()
          break
        }
        case 'SearchContactAttributesRelationsStore': {
          useSearchContactAttributesRelationsStore().fetchContactAttributes()
          break
        }
        default: {
          throw new Error('unknown Store:' + this.storeName)
        }
      }
    },
    reset() {
      this.resetFields(this.storeToUse)
    }
  },
  mounted() {
    this.fetchAttributesNow()
    if (this.initFromUrl) {
      this.initSearchStoreFromQuery(this.storeToUse, this.$route.query)
    }
  }
})
</script>
